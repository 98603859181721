'use strict';

(function ($) {

    /* --------------------------------------------------- *
     * SEARCH FORM
    /* --------------------------------------------------- */
    // $('.toggle-search').on( 'click', function(){
    // 	$('.search-form-big').toggleClass('show');
    //        $(this).toggleClass('is-active');
    // });


    /* --------------------------------------------------- *
     * NAVIGATION
    /* --------------------------------------------------- */
    // $('.site-header-navigation .menu-main-menu-container ul').superfish({
    //    delay: 300,
    //    animation: {
    //        opacity: 'show',
    //        height: 'show'
    //    },
    //    animationOut: {
    //        opacity: 'hide',
    //        height: 'hide'
    //    },
    //    speed: 200,
    //    speedOut: 200,
    //    autoArrows: false
    //    });

    /* --------------------------------------------------- *
     * STICKY HEADER
    /* --------------------------------------------------- */
    $('.site-header').sticky({
        topSpacing: 0,
        className: 'sticky'
    });

    /* --------------------------------------------------- *
     * SITE HEADER BOTTOM MENU
    /* --------------------------------------------------- */
    $('.site-header-bottom-menu .menu li.menu-item-has-children a').bind('click', function () {
        var $navigation = $(this).parent();
        var $childMenu = $navigation.find('> .sub-menu');

        if ($navigation.hasClass('is-open')) {
            $navigation.removeClass('is-open');
            $childMenu.slideUp('slow');
        } else {
            $navigation.addClass('is-open');
            $childMenu.slideDown('slow');
        }
    });

    $('.site-header-rwd .hamburger').on('click', function () {
        $(this).toggleClass('is-active');
        $('.site-header-responsive').toggleClass('show');
    });

    /* --------------------------------------------------- *
     * SCROLL TO TOP
    /* --------------------------------------------------- */
    $('.link-to-top').on('click', function () {
        $('html, body').animate({ scrollTop: 0 }, 'slow');
    });

    /* --------------------------------------------------- *
     * MAP
    /* --------------------------------------------------- */
    $(window).on("load", function () {

        $('#map-canvas').gmap({
            'center': '45.324940,-75.825441',
            'zoom': 18,
            scrollwheel: false,
            'disableDefaultUI': false,
            'styles': [{
                stylers: [{
                    lightness: 7
                }, {
                    saturation: -50
                }]
            }],
            'callback': function callback() {
                var self = this;
                self.addMarker({
                    'position': this.get('map').getCenter()
                });
            }
        });
    });

    /* --------------------------------------------------- *
     * SLIDER
    /* --------------------------------------------------- */
    // store the slider in a local variable
    var $window = $('.section-services-homepage .col-md-8'),
        flexslider = { vars: {} };

    // tiny helper function to add breakpoints
    function getGridSize() {
        return window.innerWidth < 600 ? 2 : window.innerWidth < 900 ? 3 : 4;
    }

    $(window).load(function () {
        // SLIDER HERO BANNER
        $('.section-hero-carousel').flexslider({
            animation: "fade",
            slideshow: false,
            smoothHeight: true,
            customDirectionNav: $(".section-hero .custom-nav a"),
            controlsContainer: $('.section-hero .custom-pagination .custom-pagination-content')
        });

        // CAROUSEL SERVICES IN HOMEPAGE
        // $('.services-homepage-carousel').flexslider({
        //     animation: "slide",
        //     animationLoop: false,
        //     itemWidth: 210,
        //     itemMargin: 30,
        //     smoothHeight: true,
        //     minItems: 1,
        //     maxItems: 3,
        //     customDirectionNav: $(".services-pagination-custom a"),
        //     move: 1,
        //     minItems: getGridSize(), 
        //     maxItems: getGridSize() 
        // });

        // CAROUSEL PRODUCTS
        $('.product-carousel').flexslider({
            animation: "slide",
            animationLoop: false,
            itemWidth: 540,
            itemMargin: 30,
            minItems: 2,
            maxItems: 2,
            customDirectionNav: $('.custom-nav-slider-products .custom-nav a'),
            move: 1
        });

        // CAROUSEL RELATED SERVICES IN SINGLE SERVICE
        $('.pricing-related-content').flexslider({
            animation: 'slide',
            animationLoop: false,
            itemWidth: 250,
            itemMargin: 30,
            minItems: 2,
            maxItems: 4,
            customDirectionNav: $('.custom-nav-slider-services .custom-nav a'),
            move: 1
        });
    });

    // check grid size on resize event
    $window.resize(function () {
        var gridSize = getGridSize();

        flexslider.vars.minItems = gridSize;
        flexslider.vars.maxItems = gridSize;
    });

    /* --------------------------------------------------- *
     * SLIDER SLICKS
    /* --------------------------------------------------- */
    var frontPageServices = $('.services-homepage-carousel');
    var frontPageServicesPrev = $('.services-pagination-custom .prev');
    var frontPageServicesNext = $('.services-pagination-custom .next');

    frontPageServices.slick({
        dots: false,
        cssEase: 'linear',
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [{
            breakpoint: 830,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: false
            }
        }, {
            breakpoint: 350,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: false
            }
        }]
    });

    frontPageServicesPrev.on('click', function () {
        frontPageServices.slick('slickPrev');
    });

    frontPageServicesNext.on('click', function () {
        frontPageServices.slick('slickNext');
    });

    /* --------------------------------------------------- *
     * TICKER
    /* --------------------------------------------------- */
    $('.section-testimonial-clients:has(>.item-testimonial-client:eq(1))').list_ticker({
        speed: 5000,
        effect: 'fade'
    });

    /* --------------------------------------------------- *
     * PARALLAX
    /* --------------------------------------------------- */
    $('.jarallax').jarallax({
        speed: 0.2
    });
})(jQuery);